<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-24 18:15:09
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-09-05 12:44:01
 * @FilePath: \uova\src\views\home\first\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="index">
    <div class="inputList">
      <van-form validate-first>
        <!-- rgb(88 79 79 / 68%) -->
        <van-field
          class="yanzhengma"
          v-model="value"
          name="pattern"
          placeholder="|  手机号码"
          error-message-align="left"
          :rules="[{ pattern, message: '请输入格式正确的手机号', required: true }]"
        />
      </van-form>

      <van-field class="yanzhengma" v-model="sms" center clearable placeholder="|  验证码" maxlength="6">
        <template #button>
          <van-button class="send" v-if="!content" @click="loginCaptchaList">获取验证码</van-button>
          <van-button class="send" disabled v-else>{{ content }}</van-button>
        </template>
      </van-field>
    </div>
    <van-button class="login" @click="login" type="primary">登录</van-button>
  </div>
</template>

<script>
import { loginCaptcha, captchaLogin } from './index';
import { Notify } from 'vant';
export default {
  data() {
    return {
      content: '',
      canClick: true,
      value: '',
      refreshData: null,
      sms: '',
      pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
      query: {
        captcha: '',
        phone: '',
      },
    };
  },
  methods: {
    login() {
      this.query.phone = this.value;
      this.query.captcha = this.sms;
      captchaLogin(this.query).then(res => {
        if (res.data.code == 200) {
          Notify({ type: 'success', message: '登录成功' });
          // this.$router.push('/home/detail');
          this.$router.push({
            name: 'detail',
            params: {
              // id: '20180822',
              phone: this.query.phone,
            },
          });
        } else {
          Notify({ type: 'danger', message: res.data.msg });
        }
      });
    },
    loginCaptchaList() {
      const phone = this.value;
      loginCaptcha({ phone: phone }).then(res => {
        Notify({ type: 'success', message: '发送成功' });
        this.countdowm();
      });
    },
    countdowm() {
      let totalTime = 60;
      if (!this.canClick) return; //节流
      this.canClick = false;
      this.content = totalTime + 's后重新倒计时';
      this.refreshData = window.setInterval(() => {
        totalTime--;
        this.content = totalTime + 's后重新倒计时';
        if (totalTime < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.content = '';
          totalTime = 60;
          this.canClick = true; //这里重新开启
        }
      }, 1000);
    },
  },
  //beforeDestroy为实例销毁之前调用
  beforeDestroy() {
    if (this.refreshData) {
      clearInterval(this.refreshData);
      this.refreshData = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url('../../../static/image/登录背景.png');
  background-size: 100% 100%;
  /*   background-position: center center; */
  /* background: pink; */
  .inputList {
    width: 100%;
    height: auto;
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    .yanzhengma {
      width: 80%;
      height: 54px;
      margin-left: 10%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 34px;
      background: none;
      background-image: url('../../../static/image/输入框.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      input {
        height: 50px;
        width: 60%;
        color: #000;
      }
      ::-webkit-input-placeholder {
        color: #979798;
        font-size: 16px;
        font-weight: 400;
      }
      .send {
        height: 30px;
        color: #878787;
        border: none;
        background: none;
        background-image: url('../../../static/image/验证码框.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .buttonList {
        width: 40%;
        height: 56px;
        background: none;
        color: #000;
        border: 1px solid #000;
      }
    }
  }
  .login {
    width: 50%;
    height: 50px;
    background: none;
    font-size: 18px;
    border: none;
    position: fixed;
    bottom: 20%;
    left: 50%;
    color: #fff;
    transform: translateX(-50%);
    background-image: url('../../../static/image/按钮.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
</style>
