<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-09-02 15:46:25
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-09-02 16:51:07
 * @FilePath: \uova\src\views\home\list\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="qqq">
    <div id="qrCode" ref="qrCodeDiv"></div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2';
export default {
  name: 'UovaIndex',
  data() {
    return {};
  },

  mounted: function () {
    this.$nextTick(function () {
      this.bindQRCode();
    });
  },
  methods: {
    bindQRCode: function () {
      new QRCode(this.$refs.qrCodeDiv, {
        text: 'http://ylyt.xicheng.uovaverse.com',
        width: 200,
        height: 200,
        colorDark: '#333333', //二维码颜色
        colorLight: '#ffffff', //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#qqq {
  background-color: #111;
  width: 300px;
  height: 300px;
  margin: 0 auto; /*水平居中*/
  position: relative;
}

#qrCode {
  display: inline-block;
  margin: 0 auto; /*水平居中*/
  position: relative;
  top: 15%;

  img {
    width: 200px;
    height: 200px;
    background-color: #fff; //设置白色背景色
    padding: 6px; // 利用padding的特性，挤出白边
  }
}
</style>
