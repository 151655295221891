<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-09-01 13:39:11
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-09-05 18:02:23
 * @FilePath: \uova\src\views\home\detail\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="indexList">
    <div class="big" @click="imgflag = false" v-if="imgflag">
      <p class="x" imgflag="false">x</p>
      <img src="../../../static/image/藏品阖家.png" alt="" />
    </div>
    <div class="index">
      <!-- 详情页 -->
      <div class="xiangqing">
        <img src="../../../static/image/藏品阖家.png" alt="" @click="detailMap()" />
        <p style="color: #fff; padding: 2%; text-align: center">藏品名称：花好月圆阖家欢</p>
      </div>
      <div class="xiangqing2">
        <div>
          <h3>用户须知</h3>
          &nbsp;&nbsp;&nbsp;&nbsp;“花好月圆阖家欢” 全球限量发行3650份，同一用户仅限参与一次活动，
          数字藏品将以手机号空投的方式发送给用户。
          <br />
          <br />
          如何获得?
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;“花好月圆阖家欢”数字藏品
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;用户通过“中秋元宇宙之旅”H5活动页面找齐四件特殊宝石物品即可获得。
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;本次活动中获得该数字藏品的用户，点击收到的链接，跳转到此H5页面查看藏品。
          <br />
          <br />
          什么是数字藏品?
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;1、数字藏品是依托区块链技术进行标识的经数字化的特定产品、艺术品和商品，包括但不限于数字画作、图片、音乐、视频、3D模型等各种样式。
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;2、数字藏品的版权由发行方或原创者拥有，除另行取得版权拥有者书面同意外，用户仅可为个人收藏、学习、研究、欣赏及展示目的使用，不得将数字藏品用于任何商业用途。
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;3、数字藏品一经兑换，不支持退换。
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;4、每个数字藏品映射着特定区块链上的唯一序列号，不可篡改、不可分割，也不能互相替代，每一个数字藏品都代表特定作品、艺术品和商品限量发行的单个数字复制品，记录着其不可篡改的链上权利。
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;5、请勿对数字藏品进行炒作、场外交易、欺诈，或以任何其他非法方式进行使用。
        </div>
        <div class="detailList">
          <p>区块链:文昌链</p>
          <p>持有人手机号:{{ data.phone }}</p>
          <p>
            合约地址：
            <span style="width: 100%; word-break: break-word; font-size: 14px">{{ data.owner }}</span>
          </p>
          <p>
            铸造HASH:
            <span style="width: 100%; word-break: break-word; font-size: 14px">{{ data.mintHash }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { airDrop } from './index';
export default {
  name: 'UovaIndex',
  data() {
    return {
      data: [],
      phone: void 0,
      imgflag: false,
    };
  },
  mounted() {
    this.List();
    this.airDropList();
  },

  methods: {
    detailMap() {
      this.imgflag = true;
      console.log(this.imgflag);
    },
    List() {
      if (this.$route.params.phone) {
        this.phone = this.$route.params.phone;
      } else {
        this.phone = '';
      }
    },
    airDropList() {
      const phone = this.phone;
      airDrop({ phone: phone }).then(res => {
        this.data = res.data.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.indexList {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-image: url('../../../static/image/背景.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .big {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0;
    z-index: 999;
    overflow: hidden;
    background: rgb(76 75 75 / 87%);
    .x {
      height: auto;
      font-size: 50px;
      color: #fff;
      margin-right: 2%;
      position: fixed;
      top: -5%;
      right: 5%;
    }
    img {
      width: 70%;
      height: auto;
      margin-top: 16%;
      margin-left: 15%;
    }
  }
  .index {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow-y: scroll;
    background-image: url('../../../static/image/背景.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;

    .xiangqing {
      width: 90%;
      margin-left: 5%;
      height: auto;
      background-image: url('../../../static/image/介绍框.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-top: 2%;
      img {
        width: 50%;
        height: auto;
        margin: 10px;
        margin-left: 25%;
      }
    }
    .xiangqing2 {
      width: 90%;
      margin-left: 5%;
      margin-top: 3%;
      line-height: 30px;
      color: #fff;
      background-image: url('../../../static/image/用户地矿.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 15px;
      padding-bottom: 0.2%;
      div {
        padding: 6%;
        h3 {
          text-align: center;
        }
      }
      .detailList {
        width: 80%;
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        color: #fff;
        margin-left: 6%;
        padding-left: 5px;
        border: 1px solid #fff;
        border-radius: 20px;
        margin-bottom: 10%;
        line-height: 15px;
      }
    }
  }
}
</style>
