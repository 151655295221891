/*
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-17 11:41:31
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-09-05 14:39:33
 * @FilePath: \mid-autumn\src\utils\request\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios';

const request = axios.create({
  baseURL: 'https://mid-autumn.zeroisle.com/',
  timeout: 5000,
});

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // config.baseURL = 'https://mid-autumn.zeroisle.com';
    // console.log(config.baseURL, ' config.baseURL');
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    if (error) {
      console.log('接口出问题了！！！！！！！');
    }
    return Promise.reject(error);
  }
);

export default request;
